var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
import { LAYER_NAVIGATION } from './layer-navigation.constants';
import AccessibilityNavigationModule from '../accessibility/accessibility-navigation.module';
import { getFocusableElements, initSubLevelTracking, isElementHaveStyleClass, onError, setNoScrollBody, setTabIndex, uncheckAllCheckboxInputsInContainer } from '../../common/common.tools';
import { COMMONS } from '../../common/common.constants';
import { LayerPrimaryNavigation } from '../../common/layer-primary-navigation.class';
import { sendTrackingEvent } from '../../../common/common.utils';
export default class LayerNavigationModule extends LayerPrimaryNavigation {
  constructor(componentContainer) {
    super(LAYER_NAVIGATION.CACHE.NAME, LAYER_NAVIGATION.CACHE.VALIDITY);
    this.componentContainer = componentContainer;
    this.accessibilityNavigationModule = new AccessibilityNavigationModule();
    this.accessibilityNavigationModule.init();
  }
  init() {
    this.initTriggersCheckboxEvents();
    this.initCloseButtonsMainNavigationEvents();
    this.initCloseEscapeMainNavigationEvents();
    this.initLevelItemsEvents();
    this.listenCloseLayersEvent();
  }
  listenCloseLayersEvent() {
    document.addEventListener('closeLayers', () => {
      this.onCloseMainNavigationLayer();
      this.closeSubLevelLayer();
    });
  }
  initLevelItemsEvents() {
    this.subUniverseContainer = this.componentContainer.querySelector(`.${LAYER_NAVIGATION.LAYER_NAVIGATION_CONTAINER}`);
    const subUniverseItems = this.componentContainer.querySelectorAll(`.${LAYER_NAVIGATION.LAYER_CONTAINER_ITEM}`);
    this.loader = this.subUniverseContainer.querySelector(`.${LAYER_NAVIGATION.LAYER_LOADER}`);
    [...subUniverseItems].forEach(item => {
      item.addEventListener('click', event => __awaiter(this, void 0, void 0, function* () {
        const target = event.target;
        this.initToggleSelectedElementCssClass(target);
        if (!target.classList.contains(`${LAYER_NAVIGATION.SUB_UNIVERSE_NO_CHILDREN_ITEM}`)) {
          event.preventDefault();
          this.lastFocusedParentLevelElement = item;
          this.loader.classList.add(LAYER_NAVIGATION.LOADER_SHOW_CSS);
          const {
            navigationCode,
            storeId
          } = target.dataset;
          if (!navigationCode) return;
          this.subUniverseContainer.classList.add(LAYER_NAVIGATION.LAYER_OPEN_CLASS);
          let urlNavigation = `${COMMONS.UNIVERSES_AJAX_URL}?navigationCode=${navigationCode}&device=mobile`;
          if (storeId) urlNavigation += `&store=${storeId}`;
          yield this.getSubUniverseFromServer(urlNavigation);
        }
      }));
    });
  }
  getSubUniverseFromServer(urlNavigation) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const itemsNavigationResponseText = yield this.getData(urlNavigation);
        this.successGetData(itemsNavigationResponseText, this.componentContainer);
      } catch (error) {
        onError(error);
      }
    });
  }
  initThematicLayer(element) {
    return __awaiter(this, void 0, void 0, function* () {
      const parentElement = element.parentElement;
      this.thematicContainer = parentElement.querySelector(`.${LAYER_NAVIGATION.LAYER_THEMATIC_CONTAINER}`);
      this.loader = this.thematicContainer.querySelector(`.${LAYER_NAVIGATION.LAYER_THEMATIC_CONTAINER} .${LAYER_NAVIGATION.LAYER_LOADER}`);
      const {
        navigationCode
      } = element.dataset;
      this.loader.classList.add(LAYER_NAVIGATION.LOADER_SHOW_CSS);
      if (!navigationCode) return;
      this.thematicContainer.classList.add(LAYER_NAVIGATION.LAYER_OPEN_CLASS);
      const urlNavigation = `${COMMONS.THEMATIC_AJAX_URL}?navigationCode=${navigationCode}&device=mobile`;
      yield this.getThematicContentFromServer(urlNavigation, parentElement);
    });
  }
  getThematicContentFromServer(urlNavigation, parentElement) {
    return __awaiter(this, void 0, void 0, function* () {
      try {
        const itemsNavigationResponseText = yield this.getData(urlNavigation);
        this.successGetData(itemsNavigationResponseText, this.thematicContainer, parentElement);
      } catch (error) {
        onError(error);
      }
    });
  }
  initTriggersCheckboxEvents() {
    const triggersElements = this.componentContainer.querySelectorAll(LAYER_NAVIGATION.TRIGGER_INPUTS_SELECTOR);
    triggersElements.forEach(triggerInput => {
      triggerInput.addEventListener('change', event => __awaiter(this, void 0, void 0, function* () {
        const triggerElement = event.target;
        const isThematicTriggerChecked = triggerElement.dataset.layerType === 'thematic' && triggerElement.checked;
        if (isThematicTriggerChecked) {
          yield this.initThematicLayer(triggerElement);
        }
        const isMainTrigger = isElementHaveStyleClass(triggerElement, LAYER_NAVIGATION.TRIGGER_NAVIGATION_BUTTON);
        if (triggerElement.checked && !isMainTrigger) {
          const {
            mainLevel
          } = triggerElement.dataset;
          if (mainLevel) this.mainLevelLabel = mainLevel;
          sendTrackingEvent(triggerElement);
        }
        if (!triggerElement.checked && isMainTrigger) {
          uncheckAllCheckboxInputsInContainer(this.componentContainer, LAYER_NAVIGATION.TRIGGER_SECOND_LEVEL_INPUT);
        }
        if (isMainTrigger) setNoScrollBody(triggerElement.checked);
        if (!isThematicTriggerChecked) this.updateAccessibilityForLevel(triggerElement);
      }));
    });
  }
  initCloseButtonsMainNavigationEvents() {
    const closeMainLayerButtons = this.componentContainer.querySelectorAll(`.${LAYER_NAVIGATION.LAYER_CLOSE_BUTTONS_SELECTOR}`);
    closeMainLayerButtons.forEach(closeMainLayerButton => {
      closeMainLayerButton.addEventListener('click', () => {
        this.onCloseMainNavigationLayer();
      });
    });
  }
  initCloseEscapeMainNavigationEvents() {
    this.componentContainer.addEventListener('keyup', event => {
      if (event.key === 'Escape') {
        this.onCloseMainNavigationLayer();
        this.closeSubLevelLayer();
      }
    });
  }
  InitSubLevelBackButton() {
    const subUniverseReturnButton = this.subUniverseContainer.querySelector(`.${LAYER_NAVIGATION.BACK_BUTTON}`);
    subUniverseReturnButton && subUniverseReturnButton.focus();
    subUniverseReturnButton === null || subUniverseReturnButton === void 0 ? void 0 : subUniverseReturnButton.addEventListener('click', () => {
      this.closeSubLevelLayer();
      this.lastFocusedParentLevelElement.focus();
      const triggerInputParent = this.componentContainer.querySelector(`.${LAYER_NAVIGATION.TRIGGER_SECOND_LEVEL_INPUT}`);
      this.updateAccessibilityForLevel(triggerInputParent);
    });
  }
  InitSubLevelCloseButton() {
    const subUniverseCloseButton = this.subUniverseContainer.querySelector(`.${LAYER_NAVIGATION.CLOSE_BUTTON}`);
    subUniverseCloseButton === null || subUniverseCloseButton === void 0 ? void 0 : subUniverseCloseButton.addEventListener('click', () => {
      this.closeSubLevelLayer();
      this.onCloseMainNavigationLayer();
    });
  }
  onCloseMainNavigationLayer() {
    const triggerOpenCloseMainLayer = this.componentContainer.querySelector(`.${LAYER_NAVIGATION.TRIGGER_NAVIGATION_BUTTON}`);
    const mainNavigationContainer = this.componentContainer.querySelector(`.${LAYER_NAVIGATION.MAIN_NAVIGATION_CONTAINER}`);
    const allLayerFocusableElements = mainNavigationContainer.querySelectorAll(LAYER_NAVIGATION.FOCUSABLE_ELEMENTS);
    triggerOpenCloseMainLayer.checked = false;
    uncheckAllCheckboxInputsInContainer(this.componentContainer, LAYER_NAVIGATION.TRIGGER_SECOND_LEVEL_INPUT);
    allLayerFocusableElements.forEach(element => {
      setTabIndex(element, -1);
    });
    setNoScrollBody(false);
    triggerOpenCloseMainLayer.focus();
  }
  closeSubLevelLayer() {
    this.subUniverseContainer.classList.remove(LAYER_NAVIGATION.LAYER_OPEN_CLASS);
    if (this.responseContainer) {
      this.responseContainer.innerHTML = '';
    }
  }
  successGetData(response, container, parentElement) {
    this.loader && this.loader.classList.remove(LAYER_NAVIGATION.LOADER_SHOW_CSS);
    this.responseContainer = container.querySelector(`.${LAYER_NAVIGATION.RESPONSE_CONTENT}`);
    this.responseContainer.innerHTML = response;
    this.InitSubLevelBackButton();
    this.InitSubLevelCloseButton();
    this.updateAccessibilitySubLevelLayer(parentElement || this.responseContainer);
    initSubLevelTracking(this.subUniverseContainer, this.mainLevelLabel);
  }
  updateAccessibilityForLevel(triggerElement) {
    const triggerInputParent = triggerElement.parentElement;
    const focusableElements = this.prepareFocusableElementsLayer(triggerInputParent);
    if (triggerElement.checked) {
      this.accessibilityNavigationModule.update(triggerElement, triggerElement, focusableElements);
      this.onMobileUsefullLinksLayerEvents(triggerElement, focusableElements);
    } else {
      const containerMainLayer = triggerElement.closest(`.${LAYER_NAVIGATION.NAVIGATION_LIST}`);
      const firstElement = containerMainLayer.querySelector(`.${LAYER_NAVIGATION.LAYER_CLOSE_BUTTONS_SELECTOR}`);
      const focusableElementMain = this.prepareFocusableElementsLayer(containerMainLayer);
      this.accessibilityNavigationModule.update(firstElement, triggerElement, focusableElementMain);
      focusableElements.forEach(focusableElement => {
        setTabIndex(focusableElement, -1);
      });
      this.responseContainer.innerHTML = '';
    }
  }
  prepareFocusableElementsLayer(elementContainer) {
    const focusableElements = [];
    const closeLayerButton = elementContainer.querySelector(`.${LAYER_NAVIGATION.LAYER_CLOSE_BUTTONS_SELECTOR}`);
    if (closeLayerButton && !closeLayerButton.classList.contains(`${LAYER_NAVIGATION.NO_FOCUS_CLASS}`)) {
      setTabIndex(closeLayerButton, 0);
      focusableElements.push(closeLayerButton);
    }
    const listContainer = elementContainer.querySelector(`.${LAYER_NAVIGATION.NAVIGATION_LIST} ul`);
    if (listContainer) {
      const directElementsChildren = listContainer.children;
      [...directElementsChildren].forEach(element => {
        if (element) {
          const directFocusableElement = element.querySelector(LAYER_NAVIGATION.FOCUSABLE_ELEMENTS);
          setTabIndex(directFocusableElement, 0);
          focusableElements.push(directFocusableElement);
        }
      });
    }
    return focusableElements;
  }
  updateAccessibilitySubLevelLayer(layer) {
    var _a;
    (_a = layer.querySelector(`.${LAYER_NAVIGATION.LAYER_CLOSE_BUTTONS_SELECTOR}`)) === null || _a === void 0 ? void 0 : _a.setAttribute('tabIndex', '0');
    const triggerElement = layer.querySelector(`.${LAYER_NAVIGATION.BACK_BUTTON}`);
    const focusableElements = getFocusableElements(layer);
    this.accessibilityNavigationModule.update(triggerElement, triggerElement, focusableElements);
  }
  onMobileUsefullLinksLayerEvents(triggerElement, focusableElements) {
    const useFullLinksButton = this.componentContainer.querySelector(`.${LAYER_NAVIGATION.USEFULL_LINKS_BUTTON}`);
    if (useFullLinksButton) {
      useFullLinksButton.addEventListener('click', () => {
        this.accessibilityNavigationModule.update(triggerElement, triggerElement, []);
      });
      this.componentContainer.addEventListener('addParentAccessibility', () => {
        this.accessibilityNavigationModule.update(triggerElement, triggerElement, focusableElements);
        focusableElements[0].focus();
      }, false);
    }
  }
  initToggleSelectedElementCssClass(element) {
    const elementParentList = element.closest('ul');
    const siblings = elementParentList.querySelectorAll(`.${LAYER_NAVIGATION.LAYER_CONTAINER_ITEM}`);
    const siblingsList = [...siblings].filter(x => x !== element);
    this.addSelectedElementCssClass(element);
    siblingsList.forEach(link => {
      this.removeSelectedElementCssClass(link);
    });
  }
  addSelectedElementCssClass(element) {
    element === null || element === void 0 ? void 0 : element.classList.add(`${LAYER_NAVIGATION.SELECTED_ITEM_UNIVERSE_CSS_CLASS}`);
  }
  removeSelectedElementCssClass(element) {
    element === null || element === void 0 ? void 0 : element.classList.remove(`${LAYER_NAVIGATION.SELECTED_ITEM_UNIVERSE_CSS_CLASS}`);
  }
}