import AccessibilityClass from '../../common/accessibility.class';
export default class AccessibilityNavigationModule extends AccessibilityClass {
  constructor() {
    super();
  }
  init() {
    this.initEventFocusableElements();
  }
  initEventFocusableElements() {
    document.addEventListener('keyup', this.handleKeyUpEvent.bind(this));
  }
  handleKeyUpEvent(event) {
    const {
      target,
      key
    } = event;
    if (key === 'Tab' && !event.shiftKey) {
      if (this.currentFocusableElement === this.lastFocusableElement) {
        if (this.firstFocusableElement) {
          this.firstFocusableElement.focus();
        }
      }
    }
    if (key === 'Tab' && event.shiftKey) {
      if (this.currentFocusableElement === this.firstFocusableElement) {
        this.lastFocusableElement.focus();
      }
    }
    this.currentFocusableElement = target;
  }
}