export const LAYER_NAVIGATION = {
  MAIN_NAVIGATION_CONTAINER: 'js-navigation-main-container',
  TRIGGER_INPUTS_SELECTOR: 'input[type=checkbox][class*=\'js-trigger\']',
  TRIGGER_NAVIGATION_BUTTON: 'js-trigger-main-navigation',
  LAYER_CLOSE_BUTTONS_SELECTOR: 'js-navigation-close',
  TRIGGER_SECOND_LEVEL_INPUT: 'js-trigger-item-navigation',
  NAVIGATION_LIST: 'js-layer-navigation-list',
  NO_FOCUS_CLASS: 'js-no-focus',
  SUB_UNIVERSE_NO_CHILDREN_ITEM: 'js-item-universe-no-children',
  SELECTED_ITEM_UNIVERSE_CSS_CLASS: 'm-navigation-item__layer-item-button--selected',
  LAYER_CONTAINER_ITEM: 'js-layer-container-item',
  LAYER_LOADER: 'js-layer-container-loader',
  LAYER_OPEN_CLASS: 'l-layer-navigation__container--open',
  RESPONSE_CONTENT: 'js-layer-response-content',
  BACK_BUTTON: 'js-layer-container-return',
  CLOSE_BUTTON: 'js-layer-container-close',
  LOADER_SHOW_CSS: 'l-layer-navigation__loader--show',
  HIGHLIGHTS_MESH_SWIPER: 'js-highlights-swiper',
  USEFULL_LINKS_BUTTON: 'js-open-usefullink',
  LAYER_THEMATIC_CONTAINER: 'js-thematic-container',
  LAYER_NAVIGATION_CONTAINER: 'js-navigation-container',
  LAYER_THEMATIC_OPEN_CLASS: 'l-layer-thematic__container--open',
  CACHE: {
    NAME: 'categoryNavigationMobile',
    VALIDITY: 300000
  },
  SEND_TRACKING_EVENT_ELEMENTS: '[data-cdl-custom]',
  FOCUSABLE_ELEMENTS: 'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
};